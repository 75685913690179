exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-news-json-slug-js": () => import("./../../../src/pages/news/{newsJson.slug}.js" /* webpackChunkName: "component---src-pages-news-news-json-slug-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-portfolio-json-slug-js": () => import("./../../../src/pages/portfolio/{portfolioJson.slug}.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-json-slug-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-team-json-slug-js": () => import("./../../../src/pages/team/{teamJson.slug}.js" /* webpackChunkName: "component---src-pages-team-team-json-slug-js" */)
}

